import React from 'react';
import { Link, Button } from 'gatsby-material-ui-components';
import { Box, Card, CardContent, CardActions, Typography } from '@mui/material';

const TickedList = (props) => (
  <Typography
    variant='body2'
    component='ul'
    sx={{
      paddingLeft: 2,
      'li::marker': {
        content: "'✓  '",
        color: 'text.secondary',
        fontWeight: 700
      }
    }}
  >
    {props.children}
  </Typography>
);

const CircledIcon = (props) => (
  <Box
    sx={{
      display: 'inline-block',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: 'divider',
      borderRadius: '100%',
      padding: '10px',
      lineHeight: 1
    }}
  >
    <img src={props.publicURL} width='35' height='35' alt='' />
  </Box>
);

interface FunctionCardProps {
  slug: string;
  title: string;
  subtitle: string;
  iconURL: string;
  points: string[];
}

export default (props: FunctionCardProps) => {
  return (
    <Card variant='outlined'>
      <CardContent sx={{ padding: 3 }}>
        <Box position='relative' paddingRight='70px'>
          <Link to={props.slug}>
            <Typography variant='h5' component={'h3'} textTransform='uppercase' gutterBottom>
              {props.title}
            </Typography>
            <Typography variant='h6' component={'h4'} gutterBottom>
              {props.subtitle}
            </Typography>
          </Link>
          <Box
            position='absolute'
            top={0}
            bottom={0}
            ml={-3} // == -1 * padding CardContent
            width={7}
            bgcolor='divider'
          />
          <Box position='absolute' top={0} right={0}>
            <CircledIcon publicURL={props.iconURL} />
          </Box>
        </Box>
        <TickedList>
          {props.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </TickedList>
      </CardContent>
      <CardActions sx={{ padding: 3, paddingTop: 0, justifyContent: 'space-around' }}>
        <Button to={props.slug} sx={{ minWidth: '60%' }}>
          Więcej
        </Button>
      </CardActions>
    </Card>
  );
};
