import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { Grid } from '@mui/material';
import StandardFrame from '../components/StandardFrame';
import FunctionCard from '../components/Cards/FunctionCard';
import PatientFlowHero from '../components/Heros/PatientFlowHero';
import Enumerable from 'linq';
import slugify from 'slugify';
import PageSectionTitle from '../components/Sections/PageSectionTitle';
import PageSection from '../components/Sections/PageSection';
import { ContactForm } from '../components/Sections/ContactForm';

export const pageQuery = graphql`
  query FunctionIndex {
    allMarkdownRemark(
      filter: { childFunction: { id: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              order
              title
              subtitle
              card_points
              card_icon {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default ({
  data: {
    allMarkdownRemark: { group }
  }
}: PageProps<Queries.FunctionIndexQuery>) => {
  const ordered = Enumerable.from(group)
    .orderBy(({ edges }) => Enumerable.from(edges).min(({ node }) => node.frontmatter!.order || 0))
    .select((group) => ({
      ...group,
      slug: slugify(group.fieldValue!, { lower: true })
    }))
    .toArray();

  return (
    <>
      <Helmet title='Funkcje' />
      <StandardFrame>
        <PatientFlowHero links={ordered.slice(0, 3).map((group) => '#' + group.slug)} />
        {ordered.map(({ fieldValue, slug, edges }, index) => (
          <PageSection key={index} maxWidth='lg' id={slug} bgcolor={index % 2 === 0 ? 'tertiary.main' : ' white'}>
            <PageSectionTitle>{fieldValue!}</PageSectionTitle>
            <Grid container spacing={3} mt={0}>
              {edges.map(({ node }) => (
                <Grid key={node.id} item xs={12} sm={6}>
                  <FunctionCard
                    slug={node.fields!.slug}
                    title={node.frontmatter!.title!}
                    subtitle={node.frontmatter!.subtitle!}
                    iconURL={node.frontmatter!.card_icon!.publicURL!}
                    points={node.frontmatter!.card_points!.map((point) => point!)}
                  />
                </Grid>
              ))}
            </Grid>
          </PageSection>
        ))}
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
